import styled from 'styled-components'
import { Media, mbSize } from '@renderbus/common/theme'
import Bg from '../images/ysdh-xrj-lqzn/bg.png'
import BgMb from '../images/ysdh-xrj-lqzn/bg-mb.png'
import BgTextImg from '../images/ysdh-xrj-lqzn/bg-text.png'
import BgTextImgMb from '../images/ysdh-xrj-lqzn/bg-text-mb.png'
import { Wrapper } from '@renderbus/common/components'
import CouponBg from '../images/ysdh-xrj-lqzn/coupon-bg.png'
import CouponBgMb from '../images/ysdh-xrj-lqzn/coupon-bg-mb.png'

export const XgtnzhdContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  aling-items: center;
  background-color: #222222;
`

export const RechargeButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 46px;
  border-radius: 23px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
  }
`

export const XgtnzhdPoster = styled.div`
  background: ${`url(${Bg})`} no-repeat center center;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${BgMb})`} no-repeat center center;
    background-size: 100%;
    width: 100%;
  }
`
export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: -100vh;
`

export const PosterTextImg = styled.div`
  background: ${`url(${BgTextImg})`} no-repeat center center;
  background-size: 100%;
  height: 198px;
  width: 684px;
  margin-top: 176px;
  margin-left: 0;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${BgTextImgMb})`} no-repeat center center;
    background-size: 100%;
    width: ${mbSize(697)};
    height: ${mbSize(202)};
    margin-top: ${mbSize(246)};
    margin-left: 0;
  }
`

export const GuideWrapper = styled(Wrapper)`
  margin-top: 37vh;
  ${Media.lessThan(Media.small)} {
    margin-top: 71vw;
  }
`
export const GuideTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    color: #ffecb2;
    background: linear-gradient(0deg, #ffe6b2 0%, #fff6e5 79.5166015625%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    width: 30px;
    height: 30px;
    margin: 0 21px;
  }
  ${Media.lessThan(Media.small)} {
    span {
      font-size: ${mbSize(42)};
      line-height: ${mbSize(42)};
    }
    img {
      width: ${mbSize(36)};
      height: ${mbSize(36)};
      margin: 0 ${mbSize(24)};
    }
  }
`
export const GuideContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 35px;
  margin-top: 100px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    gap: ${mbSize(87)};
    margin-top: ${mbSize(80)};
    align-items: center;
  }
`
export const GuideItem = styled.div`
  text-align: center;
  .guide-img {
    width: 250px;
    height: 300px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 250px;
      height: 300px;
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    :hover {
      img {
        transform: scale(1.06);
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    color: #12cc75;
    line-height: 21px;
    margin: 39px 0 16px;
  }
  .sub-title {
    font-size: 16px;
    color: #999999;
    margin: 0;
  }

  ${Media.lessThan(Media.small)} {
    .guide-img {
      width: ${mbSize(500)};
      height: ${mbSize(600)};
      cursor: pointer;
      img {
        width: ${mbSize(500)};
        height: ${mbSize(600)};
      }
    }
    .title {
      font-size: ${mbSize(36)};
      line-height: ${mbSize(36)};
      margin: ${mbSize(42)} 0 ${mbSize(20)};
    }
    .sub-title {
      font-size: ${mbSize(30)};
    }
  }
`
export const GuideTop = styled.div`
  background-image: url(${CouponBg});
  background-size: 100% 100%;
  width: 250px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .guide-item {
    display: flex;
    align-items: center;
  }
  .welfare-text {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    display: inline;
  }
  span {
    color: #ffecb2;
  }
  ${Media.lessThan(Media.small)} {
    background-image: url(${CouponBgMb});
    width: ${mbSize(500)};
    height: ${mbSize(144)};
    .welfare-text {
      font-size: ${mbSize(30)};
      line-height: 24px;
    }
  }
`
export const RegisterButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 70px;
  background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  border-radius: 35px;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  margin: 102px auto 120px;
  &:hover {
    box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(540)};
    height: ${mbSize(100)};
    box-shadow: 0px ${mbSize(6)} ${mbSize(11)} ${mbSize(1)} rgba(64, 194, 93, 0.3);
    margin: ${mbSize(106)} auto ${mbSize(157)};
    font-size: ${mbSize(36)};
  }
`
export const Desc = styled.div`
  font-size: 30px;
  color: #ffffff;
  line-height: 29px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 16px 0 40px;
  p {
    margin: 0;
  }
  span {
    color: #ffecb2;
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    line-height: unset;
    letter-spacing: ${mbSize(2)};
    margin: ${mbSize(16)} 0 ${mbSize(40)};
  }
`

export const Instruction = styled.div`
  font-size: 16px;
  color: #999999;
  line-height: 36px;
  margin-top: 20px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
`
